class Layout {
  constructor() {
    $(document).on("click", "button#user-menu", function() {
      $("div[aria-labelledby=user-menu]").toggleClass("hidden")
    });
    
    $(document).on("click", "a[data-action=hide-notice]", function() {
      $("div[data-area=notice]").toggleClass("hidden");
      return false;
    });
    
    $(document).on("click", ".sidebarButton", function() {
      $(".sidebarMenuMobile").toggleClass("hidden");
    })
  }
}

let layout = new Layout();
